import React, { FC, useMemo } from 'react'
import { LabelProps, TagProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import { onArrowPress } from '@/helpers/pageReferringWidgetHelper'
import { useIsOnTeamPage } from '@/helpers/teamHelper'

interface CardProps extends SystemSpacingsProps {
    title: string
    titleHref?: string
    hint?: string
    tag?: TagProps | LabelProps
    lazy?: boolean
    imageUrl?: string
    onPress?: () => void
    showHintText?: boolean
    testID?: string
}

const TeaserCardHeadline: FC<CardProps> = ({
    title,
    tag,
    marginX,
    titleHref,
    onPress,
    showHintText,
    testID,
}) => {
    const isOnTeamPage = useIsOnTeamPage()
    const cardHeadlineLink = useMemo(() => {
        if (titleHref) {
            return titleHref
        } else if (tag) {
            return tag.href
        }
    }, [tag, titleHref])

    const onArrowPressed = () => {
        onPress
        isOnTeamPage ? onArrowPress() : null
    }

    return (
        <NonFlexingContainer
            testID={testID}
            marginX={marginX}
            spaceBottom="standard"
            backgroundColor="transparent"
        >
            {cardHeadlineLink ? (
                <NonFlexingContainer>
                    <CardHeadline
                        cardHeadlineType={['cardHeadlineS', 'cardHeadlineM', 'cardHeadlineM']}
                        text={showHintText && tag ? tag.title : title}
                        rightIconName="scroll-right"
                        onPress={onArrowPressed}
                        href={cardHeadlineLink}
                        testID="teaser-card-headline-with-hint-and-link"
                    />
                </NonFlexingContainer>
            ) : (
                // Because of too much work for BE for small change we use hardCoded approach
                // for selectableText. TODO: This has to be fixed when backend is fixed
                <NonFlexingContainer>
                    <NonFlexingContainer display={['none', 'flex', 'flex']}>
                        <CardHeadline
                            cardHeadlineType={['cardHeadlineS', 'cardHeadlineM', 'cardHeadlineM']}
                            text={
                                new RegExp(/-w-/i).test(title)
                                    ? title.replace(/-w-/i, '').trim()
                                    : title
                            }
                            testID="teaser-card-headline"
                        />
                    </NonFlexingContainer>
                </NonFlexingContainer>
            )}
        </NonFlexingContainer>
    )
}

export default TeaserCardHeadline
